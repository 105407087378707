// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import ExtendableDonutChart, {
  ExtendableDonutChartProps,
} from 'components/molecules/ExtendableDonutChart';
import TabExtends from 'components/molecules/TabExtends';
import TimeStackedBarChart, {
  TimeStackedBarChartProps,
  TimeStackedData,
} from 'components/molecules/TimeStackedBarChart';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { DateTypeRange, OverallRank } from 'util/Enums';
import {
  DashboardsTenantsCompareRecycle,
  DashboardsTenantsCompareTotalEmissionWeight,
} from 'util/Types';
import {
  mergeClasses,
  monthConvertToEN,
  normalizeNumber,
  uniqRandomNumber,
} from 'util/commons';
import Csv from 'util/csv';
import styles from './TenantStatistics.module.scss';
import { useGetBoundingClientRect } from 'util/hooks/useGetBoundingClientRect';
import dayjs from 'dayjs';
import TableFilterCalendar from 'components/molecules/TableFilterCalendar';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';

export type TenantStatisticFilter = {
  dateFrom?: string;
  dateTo?: string;
};

export type TenantStatisticDonutChart = Omit<
  ExtendableDonutChartProps,
  'title'
> & {
  extendedDetail?:
    | boolean
    | {
        label: string;
        value: number;
      }[];
};

type TenantStatisticsProps = {
  filterData: TenantStatisticFilter;
  onFilterData: (filter: TenantStatisticFilter) => void;
  className?: string;
  analyticsConfig?: {
    emissionWeight?: DashboardsTenantsCompareTotalEmissionWeight;
    compareRecycle?: DashboardsTenantsCompareRecycle;
    itemMaxWeight?: string | null;
    recycleItemMinWeight?: string | null;
    totalCost?: string | null;
    isDownload?: boolean;
    isFullOfRecycleMethods?: boolean;
    tenantName?: string;
  };
  donutChartsConfig?: {
    wasteRate?: TenantStatisticDonutChart;
    recycleRate?: TenantStatisticDonutChart;
    circulationAndProcessing?: TenantStatisticDonutChart;
    emissionsCO2?: TenantStatisticDonutChart;
  };
  barChartConfig?: TimeStackedBarChartProps;
};

const TenantStatistics: React.FC<TenantStatisticsProps> = ({
  filterData,
  onFilterData,
  className,
  analyticsConfig,
  donutChartsConfig,
  barChartConfig,
}) => {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  const analyticBoardRef = useRef<HTMLDivElement>(null);
  const [analyticBoardTabChangeTrigger, setAnalyticBoardTabChangeTrigger] =
    useState<number>();
  const [tmpFilterData, setTmpFilterData] =
    useState<TenantStatisticFilter>(filterData);

  useEffect(() => {
    setTmpFilterData(filterData);
  }, [filterData]);

  const MAXIMUM_DONUT_CHART_ITEMS = 15;
  const GRAY_COLORS_PALETTE = ['#E7E8E9', '#B7B7B7', '#878787', '#5E5E5E'];
  const MINIMUM_PRIMARY_COLORS_NUM = 4;
  const currentMonth = dayjs().get('month') + 1;

  const { domRect } = useGetBoundingClientRect(
    {
      el: analyticBoardRef.current,
    },
    [analyticsConfig, analyticBoardTabChangeTrigger]
  );

  const renderDonutChart = (params: {
    config?: TenantStatisticDonutChart;
    title: string;
  }) => {
    const { config, title } = params;

    if (!config) {
      return null;
    }

    return (
      <ExtendableDonutChart
        {...config}
        className={styles.DonutChartItem}
        title={title}
        maxDisplayItems={MAXIMUM_DONUT_CHART_ITEMS}
        chartOptions={{
          emptyMessage: t('dashboard.empty_chart_data') || '',
          percentShowLimit: 3,
          customCore: {
            tooltipOdd: 3,
          },
          datasetsConfig: {
            borderWidth: 0,
          },
          ...config.chartOptions,
        }}
        colorsSetting={{
          endColors: GRAY_COLORS_PALETTE,
          minimumPrimaryColors: MINIMUM_PRIMARY_COLORS_NUM,
        }}
      />
    );
  };

  const renderAnalyticTabs = () => {
    const analyticEmissionWeightRate = Number(
      analyticsConfig?.emissionWeight?.rate ?? 0
    );

    const rateEmissionWeight = normalizeNumber({
      value: Math.abs(analyticEmissionWeightRate),
    });

    const rateValue = `${rateEmissionWeight}%${
      analyticEmissionWeightRate > 0 ? 'UP' : 'DOWN'
    }`;

    let rate = 0;
    const analyticCurrentMonthRecycle = Number(
      analyticsConfig?.compareRecycle?.currentMonth ?? 0
    );
    const analyticPreviousMonthRecycle = Number(
      analyticsConfig?.compareRecycle?.previousMonth ?? 0
    );

    if (analyticPreviousMonthRecycle === 0) {
      if (analyticCurrentMonthRecycle !== 0) {
        rate = 100;
      }
    } else {
      rate =
        ((analyticCurrentMonthRecycle - analyticPreviousMonthRecycle) /
          analyticPreviousMonthRecycle) *
        100;
    }

    const siteRankRecycle =
      analyticsConfig?.compareRecycle?.siteRank?.split('/');

    const rateRecycle = `${normalizeNumber({
      value: Math.abs(Number(rate)),
    })}%${Number(rate) > 0 ? 'UP' : 'DOWN'}`;

    const overallRankStyle = (overallRank?: OverallRank) => {
      switch (overallRank) {
        case OverallRank.high:
          return {
            text: t('tenant_dashboard.overall_rank_high'),
            styles: mergeClasses(styles.overallRankHigh, {
              [styles.download]: analyticsConfig?.isDownload,
            }),
          };
        case OverallRank.medium:
          return {
            text: t('tenant_dashboard.overall_rank_medium'),
            styles: mergeClasses(styles.overallRankMedium, {
              [styles.download]: analyticsConfig?.isDownload,
            }),
          };
        case OverallRank.low:
          return {
            text: t('tenant_dashboard.overall_rank_low'),
            styles: mergeClasses(styles.overallRankLow, {
              [styles.download]: analyticsConfig?.isDownload,
            }),
          };
        default:
          return {
            text: '',
            styles: '',
          };
      }
    };

    const renderAnalyticTagValue = (
      val: string | number | null | undefined
    ) => {
      if (val == null || val.toString().trim() === '') {
        return <></>;
      }

      return val;
    };

    const tabItems = [
      {
        title: t('tenant_dashboard.weight'),
        content: (
          <div className={styles.content}>
            <p
              className={mergeClasses({
                [styles.download]: analyticsConfig?.isDownload,
              })}
            >
              {analyticsConfig?.emissionWeight != null ? (
                <Trans
                  i18nKey="tenant_dashboard.weight_warning"
                  components={
                    {
                      weight: (
                        <span
                          className={mergeClasses(styles.rateGreen, {
                            [styles.download]: analyticsConfig?.isDownload,
                          })}
                        />
                      ),
                      rate: (
                        <span
                          className={mergeClasses(
                            analyticEmissionWeightRate > 0
                              ? styles.rateRed
                              : styles.rateGreen,
                            {
                              [styles.download]: analyticsConfig?.isDownload,
                            }
                          )}
                        />
                      ),
                      item: (
                        <span
                          className={styles.itemNameStyle}
                          style={{
                            display: analyticsConfig.itemMaxWeight
                              ? 'block'
                              : 'none',
                          }}
                        />
                      ),
                      tenantName: renderAnalyticTagValue(
                        analyticsConfig.emissionWeight.tenantName
                      ),
                      itemName: renderAnalyticTagValue(
                        analyticsConfig.itemMaxWeight
                      ),
                    } as any
                  }
                  values={{
                    month:
                      i18n.language === 'ja'
                        ? currentMonth
                        : monthConvertToEN(currentMonth),
                    totalWeight: `${normalizeNumber({
                      value: analyticsConfig.emissionWeight.totalWeight,
                    })}kg`,
                    rateValue,
                  }}
                />
              ) : (
                t('messages.M_008')
              )}
            </p>
          </div>
        ),
      },
      {
        title: t('tenant_dashboard.recycle_rate'),
        content: (
          <div className={styles.content}>
            <p
              className={mergeClasses({
                [styles.download]: analyticsConfig?.isDownload,
              })}
            >
              {analyticsConfig?.compareRecycle != null ? (
                <Trans
                  i18nKey="tenant_dashboard.recycle_rate_warning"
                  components={
                    {
                      currRate: (
                        <span
                          className={mergeClasses(styles.rateGreen, {
                            [styles.download]: analyticsConfig?.isDownload,
                          })}
                        />
                      ),
                      rate: (
                        <span
                          className={mergeClasses(
                            Number(rate) > 0
                              ? styles.rateGreen
                              : styles.rateRed,
                            {
                              [styles.download]: analyticsConfig?.isDownload,
                            }
                          )}
                        />
                      ),
                      site: (
                        <span
                          style={{
                            display: analyticsConfig.compareRecycle.siteRank
                              ? 'block'
                              : 'none',
                          }}
                        />
                      ),
                      siteRank: (
                        <span
                          className={mergeClasses(styles.textGreen, {
                            [styles.download]: analyticsConfig?.isDownload,
                          })}
                        />
                      ),
                      rank: (
                        <span
                          style={{
                            display: analyticsConfig.compareRecycle.overallRank
                              ? 'block'
                              : 'none',
                          }}
                        />
                      ),
                      overall: (
                        <span
                          className={
                            overallRankStyle(
                              analyticsConfig?.compareRecycle?.overallRank
                            ).styles
                          }
                        />
                      ),
                      item: (
                        <span
                          className={styles.itemNameStyle}
                          style={{
                            display:
                              analyticsConfig.recycleItemMinWeight &&
                              !analyticsConfig.isFullOfRecycleMethods
                                ? 'block'
                                : 'none',
                          }}
                        />
                      ),
                      allRecycle: (
                        <span
                          className={styles.itemNameStyle}
                          style={{
                            display: analyticsConfig.isFullOfRecycleMethods
                              ? 'block'
                              : 'none',
                          }}
                        />
                      ),
                      tenantName: renderAnalyticTagValue(
                        analyticsConfig.compareRecycle.tenantName ??
                          analyticsConfig.tenantName
                      ),
                      siteName: renderAnalyticTagValue(
                        analyticsConfig.compareRecycle.siteName
                      ),
                      itemName: renderAnalyticTagValue(
                        analyticsConfig.recycleItemMinWeight
                      ),
                      industry: renderAnalyticTagValue(
                        analyticsConfig.compareRecycle.industry
                      ),
                    } as any
                  }
                  values={{
                    month:
                      i18n.language === 'ja'
                        ? currentMonth
                        : monthConvertToEN(currentMonth),
                    currentMonth: `${normalizeNumber({
                      value: analyticsConfig.compareRecycle.currentMonth,
                    })}%`,
                    rateRecycle: rateRecycle,
                    rateStyle: Number(rate) > 0 ? 'rateGreen' : 'rateRed',
                    siteRank1: siteRankRecycle?.[0],
                    siteRank2: siteRankRecycle?.[1],
                    overallRank: overallRankStyle(
                      analyticsConfig.compareRecycle.overallRank
                    ),
                  }}
                />
              ) : (
                t('messages.M_008')
              )}
            </p>
          </div>
        ),
      },
    ];

    return (
      <TabExtends
        tabItems={tabItems}
        contentRef={analyticBoardRef}
        onTabChange={() => setAnalyticBoardTabChangeTrigger(uniqRandomNumber())}
      />
    );
  };

  const handleDownloadCsv = (
    autoTimeLabeler: (data: Omit<TimeStackedData, 'data'>) => string
  ) => {
    new Csv({
      headers: [
        [
          'time',
          barChartConfig?.filterType === DateTypeRange.YEAR
            ? t('common.year')
            : barChartConfig?.filterType === DateTypeRange.DAY
            ? t('common.date')
            : t('common.month'),
        ],
        ['name', t('tenant_dashboard.item')],
        ['weight', t('tenant_dashboard.waste_volume')],
      ],
      data:
        barChartConfig?.stackedData
          ?.map((item) =>
            item.data.map((it) => ({
              time: autoTimeLabeler(item),
              name: it.label,
              weight: normalizeNumber({
                value: it.value,
                toFixed: false,
              }),
            }))
          )
          .flat() ?? [],
    }).downLoadCsv({
      filename: t('tenant_dashboard.resource_generation_file_name', {
        value: user.tenantName || '',
      }),
    });
  };

  const ContentBox = () => {
    return (
      <div
        className={mergeClasses(styles.costContent, {
          [styles.costStyles]: Number(analyticsConfig?.totalCost) !== 0,
        })}
        style={{
          height: domRect?.height,
        }}
      >
        <div className={styles.title}>{t('tenant_dashboard.cost')}</div>
        <div className={styles.totalCost}>{`${normalizeNumber({
          value: analyticsConfig?.totalCost,
          toFixed: 0,
        })} ${t('tenant_dashboard.yen')}`}</div>
      </div>
    );
  };

  const handleChangeDateRange = (value: [string, string] | undefined) => {
    setTmpFilterData({
      dateFrom: value?.[0] ? getSlaFormatYearMonthDate(value[0]) : undefined,
      dateTo: value?.[1] ? getSlaFormatYearMonthDate(value[1]) : undefined,
    });
  };

  const renderDonutAndBarChart = (params: {
    config?: TenantStatisticDonutChart;
    title: string;
  }) => {
    const { config, title } = params;

    if (!config) {
      return null;
    }

    return (
      <ExtendableDonutChart
        {...config}
        title={title}
        chartOptions={{
          emptyMessage: t('dashboard.empty_chart_data') || '',
          percentShowLimit: 3,
          customCore: {
            tooltipOdd: 3,
          },
          datasetsConfig: {
            borderWidth: 0,
          },
          ...config.chartOptions,
        }}
        colorsSetting={{
          endColors: GRAY_COLORS_PALETTE,
          minimumPrimaryColors: MINIMUM_PRIMARY_COLORS_NUM,
        }}
        extendedDetailChartTitle={{
          donut: t('tenant_dashboard.rate_of_processing_cost') || '',
          bar: t('tenant_dashboard.processing_unit_price') || '',
        }}
      >
        <ContentBox />
      </ExtendableDonutChart>
    );
  };

  return (
    <div className={mergeClasses(className, styles.tenantStatistics)}>
      <div className={styles.DonutChartZone}>
        <div className={styles.contentBox}>
          <div className={styles.costWrapper}>
            <div className={styles.FilterForm}>
              <TableFilterCalendar
                containerClassName={styles.DateRangePicker}
                expanded={{
                  placeholder: t('common.placeholder.select_date_range'),
                  icon: <IconCalendarTablet />,
                }}
                fullWidth
                value={[
                  tmpFilterData.dateFrom || '',
                  tmpFilterData.dateTo || '',
                ]}
                allowClear={false}
                onChange={handleChangeDateRange}
                chosenDatesLimit={{
                  limit: 60,
                  required: true,
                }}
              />

              <button
                type="button"
                onClick={() =>
                  onFilterData?.({
                    ...filterData,
                    ...tmpFilterData,
                  })
                }
              >
                {t('dashboard.button_apply')}
              </button>
            </div>

            {renderDonutAndBarChart({
              config: donutChartsConfig?.circulationAndProcessing,
              title: t('tenant_dashboard.circular_and_processing'),
            })}
          </div>

          {renderAnalyticTabs()}
        </div>
        <div className={styles.DonutChartsWrapper}>
          {renderDonutChart({
            config: donutChartsConfig?.wasteRate,
            title: t('tenant_dashboard.waste_rate'),
          })}
          {renderDonutChart({
            config: donutChartsConfig?.recycleRate,
            title: t('tenant_dashboard.recycle_rate'),
          })}
          {renderDonutChart({
            config: donutChartsConfig?.emissionsCO2,
            title: t('tenant_dashboard.CO2_emissions_rate'),
          })}
        </div>
      </div>

      {barChartConfig && (
        <TimeStackedBarChart
          className={styles.BarChartZone}
          maximumStacks={MAXIMUM_DONUT_CHART_ITEMS}
          colorsSetting={{
            endColors: GRAY_COLORS_PALETTE,
            minimumPrimaryColors: MINIMUM_PRIMARY_COLORS_NUM,
          }}
          onCsvDownload={handleDownloadCsv}
          {...barChartConfig}
        />
      )}
    </div>
  );
};

export default TenantStatistics;
