// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { mergeClasses } from 'util/commons';
import styles from './EditableCell.module.scss';
import { DefaultTFuncReturn } from 'i18next';
import { FORM_ERROR_CLASSNAME } from 'util/ConstantValues';

export type EditableTableCellValue = string | number | undefined | null;

export type EditableTableCellProps = {
  editable?: boolean;
  editing?: boolean;
  value?: EditableTableCellValue | EditableTableCellValue[];
  inputProps?: Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'name'
  >;
  className?: string;
  subCellClassName?: string;
  name?: string;
  onChange?: (params?: {
    name?: string;
    value?: EditableTableCellValue | EditableTableCellValue[];
    subIndex?: number;
  }) => void;
  regex?: RegExp;
  error?: string | DefaultTFuncReturn | string[] | DefaultTFuncReturn[];
};

const EditableTableCell: React.FC<EditableTableCellProps> = ({
  editable,
  editing,
  value,
  inputProps,
  className,
  subCellClassName,
  name,
  onChange,
  regex,
  error,
}) => {
  const renderError = (index?: number) => {
    if (!error) {
      return null;
    }

    if (typeof error === 'object') {
      if (index == null) {
        return <p className={styles.Error}>{error.join('\n')}</p>;
      }

      if (error[index]) {
        return <p className={styles.Error}>{error[index]}</p>;
      }

      return null;
    }

    return <p className={styles.Error}>{error}</p>;
  };

  if (value == null || typeof value !== 'object') {
    if (editing && editable) {
      return (
        <td className={mergeClasses(styles.EditableCell, className)}>
          <input
            value={value != null ? value.toString() : ''}
            type="text"
            onChange={({ target: { value } }) => {
              if (!value || !regex || regex.test(value)) {
                onChange?.({
                  name,
                  value,
                });
              }
            }}
            {...inputProps}
            className={mergeClasses(inputProps?.className, {
              [styles.ErrorInput]: error?.length,
              [FORM_ERROR_CLASSNAME]: error?.length,
            })}
          />
          {renderError()}
        </td>
      );
    }

    return (
      <td className={mergeClasses(styles.EditableCell, className)}>
        <p>{value}</p>
      </td>
    );
  }

  return (
    <td className={mergeClasses(styles.EditableCell, className)}>
      {(value.length > 0 ? value : ['']).map((val, index) => (
        <tr key={index} className={subCellClassName}>
          <td>
            {editing && editable ? (
              <>
                <input
                  value={val != null ? val.toString() : ''}
                  type="text"
                  onChange={({ target: { value } }) => {
                    if (!value || !regex || regex.test(value)) {
                      onChange?.({
                        name,
                        value,
                        subIndex: index,
                      });
                    }
                  }}
                  {...inputProps}
                  className={mergeClasses(inputProps?.className, {
                    [styles.ErrorInput]: error?.[index]?.length,
                    [FORM_ERROR_CLASSNAME]: error?.[index]?.length,
                  })}
                />
                {renderError(index)}
              </>
            ) : (
              <p>{val}</p>
            )}
          </td>
        </tr>
      ))}
    </td>
  );
};

EditableTableCell.defaultProps = {
  editable: true,
};

export default EditableTableCell;
