// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import { InputDataListField } from 'components/molecules/InputDataListField';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {
  selectTare,
  selectTenants,
  setTares,
} from 'redux/slices/siteInfoSlice';
import { selectUser } from 'redux/slices/userSlice';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DbManager } from 'util/DbManager';
import { CategoryItem, ItemType, TareData, TareItem } from 'util/Types';
import { handleError } from 'util/errorHandler';
import useNetworkConnection from 'util/hooks/useNetworkConnection';
import { useQuery } from 'util/queryParams';
import styles from './EmissionInput.module.scss';
import { validateInputEmissionNumber } from 'util/validator';
import {
  DATE_FORMAT,
  QUANTITY_INPUT_MAX_LENGTH,
  REGEX_OBJECT,
} from 'util/ConstantValues';
import { selectLoading } from 'redux/slices/loadingSlice';
import { EmissionConfirmModal } from 'components/molecules/EmissionConfirmModal';
import {
  fnGetDataSyncTime,
  getUnitLabel,
  mergeClasses,
  normalizeNumber,
} from 'util/commons';
import dayjs from 'dayjs';
import Toggle from 'components/atoms/Toggle';
import { useConnectToScale } from 'util/hooks/useConnectToScale';
import { useCheckTenantIdQuery } from 'util/hooks/useCheckTenantIdQuery';
import { useWeightInput } from 'util/hooks/useScaleWeightInput';

type Props = {
  emissionType: CategoryItem;
  afterSubmit: () => void;
};

type Input = {
  quantity?: string;
};

// FIXME: not used
// const getInputUnit = (wasteUnit) => {
//   if (wasteUnit && wasteUnit['inputUnit']) {
//     return wasteUnit['inputUnit'];
//   }
//   return 'kg';
// };

export function EmissionInput({ emissionType, afterSubmit }: Props) {
  const dispatch = useDispatch();
  const api = createAxios();
  const user = useSelector(selectUser);
  const history = useHistory();
  const tare = useSelector(selectTare);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<TareItem>();
  const query = useQuery();
  const tenantId = query.get('tenantId');
  const { t } = useTranslation();
  const isOnline = useNetworkConnection();
  const tenants = useSelector(selectTenants);
  const { offlineInitialized } = useSelector(selectLoading);
  const { scaleConnected, handleChangeScaleConnectionStatus } =
    useConnectToScale();

  const { weightInputRef } = useWeightInput({
    receiveValueFromScaleCondition: !isConfirm,
    itemType: emissionType.type,
  });

  useCheckTenantIdQuery();

  const initInputData: Input = {
    quantity: '',
  };

  const fetchTares = async () => {
    if (!offlineInitialized) {
      return;
    }

    const cachedSites = await DbManager.sites.toArray();

    if (cachedSites.length) {
      const cachedTares = await DbManager.tares.toArray();

      dispatch(
        setTares({
          page: 0,
          total: cachedTares.length,
          tares: cachedTares,
        })
      );

      return;
    }

    api
      .get<TareData>(`/tares?siteId=${user.siteId}`)
      .then(async (res) => {
        dispatch(setTares(res.data));

        await DbManager.tares.clear();
        await DbManager.tares.bulkAdd(res.data.tares);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleSelected = (tareItem: TareItem) => {
    setSelectedItem(tareItem);
  };

  useEffect(() => {
    fetchTares();
  }, [offlineInitialized]);

  const handleCallFunctionInit = () => {
    try {
      window && window.regisEmissionInitial();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleCallFunctionInit();
  }, []);

  const getConfirmQuantity = (quantity?: string) => {
    if (emissionType.type === ItemType.OPTIONAL && selectedItem) {
      const subQuantity = Number(quantity) - Number(selectedItem.weight);

      return subQuantity < 0
        ? 0
        : normalizeNumber({
            value: subQuantity,
            commaSplit: false,
          });
    }

    return quantity;
  };

  const afterAddNew = (isSubmitAndFinish?: boolean) => {
    setIsConfirm(false);
    if (!isSubmitAndFinish || !user.isShowTenants) {
      afterSubmit();
    } else {
      history.push(Path.selectTenant);
    }
    toast.success(t('messages.M_109'));
  };

  const saveLocalWasteRegistrations = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    waste: any[],
    quantity?: string
  ) => {
    const total = Number(emissionType.convertKg) * Number(quantity);

    if (total.toString().length > 10) {
      toast.error(t('emission_input.weight_too_large'));
      return Promise.reject();
    }
    const categoryId =
      emissionType.categoryId != null
        ? Number(emissionType.categoryId)
        : undefined;
    let categoryName = emissionType.categoryName;

    if (categoryId != null && categoryName == null) {
      const cachedCategory = await DbManager.categories
        .where('id')
        .equals(categoryId)
        .first();

      if (cachedCategory) {
        categoryName = cachedCategory.name;
      }
    }

    const selectedTenant = tenants.find(
      (tenant) => tenant.id === Number(tenantId)
    );

    // add wasteUnitName, tenantName, timeRegis
    const records = waste.map((record) => ({
      ...record,
      ...(categoryId != null &&
        categoryName != null && {
          categoryId,
          categoryName,
        }),
      type: emissionType.type,
      wasteUnitName: emissionType.name,
      tenantName: selectedTenant?.name,
      timeRegis: new Date().toISOString(),
      createdBy: user.userId,
    }));

    // save records to indexedDb
    await DbManager.wasteRegistrations.bulkAdd(records);

    return [
      {
        id: null,
        tenant: {
          id: selectedTenant?.id,
          name: selectedTenant?.name,
        },
        wasteUnit: {
          id: emissionType.id,
          name: emissionType.name,
        },
        emissionDatetime: dayjs().format(DATE_FORMAT.slaYMDHms),
        materialName: emissionType.materialName,
        site: {
          id: user.siteId,
          name: user.siteName,
        },
        weight: emissionType.convertKg
          ? total.toString()
          : quantity?.toString(),
      },
    ];
  };

  const submitEmissions = async (
    values: Input,
    isSubmitAndFinish?: boolean
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const waste = [] as any[];
    if (!isConfirm) return;

    if (values.quantity && values.quantity != '') {
      const quantity = getConfirmQuantity(values.quantity);
      if (isNaN(Number(quantity))) {
        alert(t('emission_input.input_number'));
      }

      waste.push({
        type: emissionType.id,
        quantity: Number(quantity),
        ...(emissionType.id && { wasteUnitId: emissionType.id }),
        ...(tenantId && { tenantId: Number(tenantId) }),
        ...(emissionType.type === ItemType.OPTIONAL && {
          tareName: selectedItem?.name,
          tareWeight: selectedItem?.weight,
        }),
      });
    }

    if (waste.length === 0) {
      return;
    }

    const dataSyncTime = await fnGetDataSyncTime();

    if (!dataSyncTime && isOnline) {
      api
        .post('/emissions', { waste })
        .then((res) => {
          afterAddNew(isSubmitAndFinish);

          if (window && window.postEmissions) {
            window.postEmissions(
              JSON.stringify([
                {
                  ...res.data[0],
                  emissionDatetime: getFormatDateWithCurrentTimezone(
                    res.data[0].emissionDatetime,
                    DATE_FORMAT.slaYMDHms
                  ),
                },
              ]),
              i18n.language
            );
          }
        })
        .catch(async (e) => {
          if (e?.message.includes('Android')) {
            return;
          }

          if (e?.message === 'Network Error') {
            saveLocalWasteRegistrations(
              waste,
              getConfirmQuantity(values.quantity)?.toString()
            ).then((data) => {
              afterAddNew(isSubmitAndFinish);
              window?.postEmissions?.(JSON.stringify(data), i18n.language);
            });

            return;
          }

          handleError(e);
        });
    } else {
      saveLocalWasteRegistrations(
        waste,
        getConfirmQuantity(values.quantity)?.toString()
      ).then((data) => {
        afterAddNew(isSubmitAndFinish);
        window?.postEmissions?.(JSON.stringify(data), i18n.language);
      });
    }
  };

  return (
    <div className={styles.emissionInput}>
      <div className={styles.scaleConnectToggle}>
        <Toggle
          content={t('emission_input.connect_with_scale') || ''}
          value={scaleConnected}
          setValue={handleChangeScaleConnectionStatus}
          isDarkMode
        />
      </div>

      <Form<Input>
        initialValue={initInputData}
        validate={(values) => {
          return {
            quantity: validateInputEmissionNumber(
              values.quantity || '',
              emissionType.type
            ),
          };
        }}
        onSubmit={(values) => {
          submitEmissions(values);
        }}
      >
        {({ handleSubmit, valid, values }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.formInput}>
              <div className={styles.scaleConnectToggleMobile}>
                <Toggle
                  content={t('emission_input.connect_with_scale') || ''}
                  value={scaleConnected}
                  setValue={handleChangeScaleConnectionStatus}
                  isDarkMode
                />
              </div>

              <div className={styles.inputType}>
                <div className={styles.commonInfo}>
                  <div className={styles.info}>
                    <div className={styles.labelBox}>
                      <div className={styles.label}>{emissionType.name}</div>
                      {emissionType.type === ItemType.FIX && (
                        <div className={styles.type}>
                          <div className={`${styles.itemType}`}>
                            <IconFixedItem />
                          </div>
                        </div>
                      )}
                    </div>

                    {emissionType.type === ItemType.FIX && (
                      <div className={styles.weightFix}>
                        {`${emissionType.convertKg}kg/${
                          emissionType.quantity
                        }${getUnitLabel(emissionType.unit)}`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.weight}>
                <div className={styles.weightField}>
                  <InputDataListField
                    placeholder="0"
                    inputRef={weightInputRef}
                    name="quantity"
                    required
                    type="text"
                    maxlength={QUANTITY_INPUT_MAX_LENGTH}
                    hidePlaceholderOnFocus
                    validRegex={
                      emissionType.type === ItemType.FIX
                        ? REGEX_OBJECT.number
                        : REGEX_OBJECT.fiveDigitsWith2Decimal
                    }
                  />
                  {emissionType.type !== ItemType.FIX && (
                    <span className={styles.weightUnit}>kg</span>
                  )}
                </div>
              </div>

              <button
                className={`${styles.submitButton} ${
                  !valid ? styles.disableButton : ''
                }`}
                disabled={!valid}
                type="button"
                onClick={() => {
                  setIsConfirm(true);
                  return false;
                }}
              >
                {t('emission_input.button_submit')}
              </button>

              {emissionType.type === ItemType.OPTIONAL && (
                <div className={styles.emissionTare}>
                  {tare?.tares.map((item) => {
                    const tareName = `${item.name} (${normalizeNumber({
                      value: item.weight,
                    })}kg)`;
                    return (
                      <span
                        key={item.id}
                        className={mergeClasses({
                          [styles.emissionSelected]:
                            selectedItem?.id === item.id,
                        })}
                        onClick={() => {
                          handleSelected(item);
                        }}
                      >
                        {`${
                          tareName.length > 20
                            ? `${tareName.slice(0, 20)}...`
                            : `${tareName}`
                        }`}
                      </span>
                    );
                  })}
                </div>
              )}

              <EmissionConfirmModal
                isOpen={isConfirm}
                emissionType={emissionType}
                closeEditModal={() => setIsConfirm(false)}
                onClickSubmitAndFinish={() => {
                  submitEmissions(values, true);
                }}
                onClick={handleSubmit}
                quantity={`${getConfirmQuantity(values.quantity)}`}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
}
