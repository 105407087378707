// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useLayoutEffect, useState } from 'react';
import { FORM_ERROR_CLASSNAME } from 'util/ConstantValues';

export const useScrollToFirstError = <T>(params?: {
  errors?: T;
  errorSelector?: string;
}) => {
  const { errors, errorSelector = `.${FORM_ERROR_CLASSNAME}` } = params ?? {};

  const [scrollToFirstError, setScrollToFirstError] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (scrollToFirstError && errors) {
      const errorElement = document.querySelector(errorSelector);
      if (errorElement && typeof errorElement.scrollIntoView === 'function') {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [errors, scrollToFirstError]);

  return {
    setScrollToFirstError,
  };
};
